import "./App.css";
import React from "react";
import { useRoutes } from "react-router-dom";
import Solve from "./solve";
import NotFound from "./404";
import Home from "./home";
import End from "./end";
import Learn from "./learn";

function App() {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "*", element: <NotFound /> },
    { path: "/solve/:qid", element: <Solve /> },
    { path: "/learn", element: <Learn /> },
    { path: "/end", element: <End /> },
  ]);
  return routes;
}

export default App;
