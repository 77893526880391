import "./App.css";
import {
  Container,
  Navbar,
  Table,
  Modal,
  Toast,
  Row,
  Col,
  Nav,
  ToastContainer,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React from "react";
import { FaMicrophone, FaSun, FaMoon, FaPlus, FaMinus } from "react-icons/fa";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import beep from "../src/sound/beep.mp3";
import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";
import { mathcompatiblespeech } from "./MathSpeech.js";
import useLocalStorage from "use-local-storage";
import KeyPress from "./keyPress";
import useSound from "use-sound";

function End() {
  const navigate = useNavigate();
  const [beepSound] = useSound(beep);
  const [fontSize, setFontSize] = useState(25);
  const [showToast, setShowToast] = useState(false);
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const defaultRate = window.matchMedia("(prefers-rate: 1)").matches;
  const defaultVoice = window.matchMedia("(prefers-voice: 0)").matches;
  const defaultMode = window.matchMedia("(prefers-mode: 1)").matches;
  const [modeOption, setMode] = useLocalStorage("mode", defaultMode ? 0 : 1);
  const [rateOption, setRate] = useLocalStorage("rate", defaultRate ? 2 : 1);
  const [voiceOption, setVoice] = useLocalStorage("voice", defaultVoice);
  const { cancel, speak, voices } = useSpeechSynthesis();
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );
  const [value, setValue] = useState("");
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      speech_action(result);
      setShowToast(true);
      setValue(result);
    },
  });

  const switchRate = () => {
    if (rateOption === 1) {
      speakMath("Changed Rate to, fast");
      setRate(rateOption + 1);
    } else {
      speakMath("Changed Rate to, slow");
      setRate(rateOption - 1);
    }
  };

  function speech_action(action) {
    if (action.includes("random") || action.includes("practice")) {
      stop();
      setTimeout(function () {
        if (modeOption === 1) {
          speakMath("Picked a question for you to practice. Redirecting now.");
        } else {
          beepSound();
        }
      }, 1000);
      setTimeout(function () {
        navigate("/solve/1");
      }, 4000);
    }

    if (action.includes("increase") || action.includes("magnify")) {
      stop();
      setTimeout(function () {
        if (modeOption === 1) {
          speakMath("Increasing font size by 10..");
        } else {
          beepSound();
        }
      }, 1000);
      setTimeout(function () {
        setFontSize(fontSize + 10);
      }, 4000);
    }

    if (action.includes("decrease")) {
      stop();
      setTimeout(function () {
        if (modeOption === 1) {
          speakMath("Decreasing font size by 10..");
        } else {
          beepSound();
        }
      }, 1000);
      setTimeout(function () {
        setFontSize(fontSize - 10);
      }, 4000);
    }

    if (action.includes("color") || action.includes("theme")) {
      stop();
      setTimeout(function () {
        switchTheme();
      }, 1000);
    }
  }

  const speakMath = (text) => {
    cancel();
    var speech = mathcompatiblespeech(text);
    speak({ text: speech, voice: voices[voiceOption] });
  };

  const enableZoomIn = (event) => {
    setFontSize(fontSize + 3);
  };

  const enableZoomOut = (event) => {
    setFontSize(fontSize - 3);
  };

  const disableSpeech = (event) => {
    if (event.key === "s") {
      setValue("");
      setShowToast(false);
      stop();
    }
  };

  const enableSpeech = (event) => {
    document.getElementById("longPressMic").click();
    try {
      listen();
    } catch {
      stop();
      console.log("speech already started");
    }
    setShowToast(true);
  };

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";

    if (modeOption === 1) {
      speakMath("Theme changed to " + newTheme);
    } else {
      beepSound();
    }
    setTheme(newTheme);
  };

  const switchVoice = (option, text) => {
    if (modeOption === 1) {
      speak({
        text: "Default Voice changed to: " + text,
        voice: voices[option],
      });
    } else {
      beepSound();
    }
    setVoice(option);
  };

  const switchMode = () => {
    if (modeOption === 1) {
      if (modeOption === 1) {
        speakMath("Changed mode to, sound");
      } else {
        beepSound();
      }
      setMode(0);
    } else {
      if (modeOption === 1) {
        speakMath("Changed Rate to, speech");
      } else {
        beepSound();
      }
      setMode(1);
    }
  };

  KeyPress(["s"], enableSpeech);
  KeyPress(["shift", "+"], enableZoomIn);
  KeyPress(["shift", "-"], enableZoomOut);
  KeyPress(["shift", "T"], switchTheme);
  KeyPress(["shift", "!"], () => switchVoice(0, "U S English Male - 1"));
  KeyPress(["shift", "@"], () => switchVoice(1, "U S English Male - 2"));
  KeyPress(["shift", "#"], () => switchVoice(2, "U S English Female"));
  KeyPress(["shift", "$"], () => switchVoice(5, "U K English Female"));
  KeyPress(["shift", "%"], () => switchVoice(6, "U K English Male"));
  KeyPress(["shift", "("], () => switchRate());
  KeyPress(["shift", ")"], () => switchMode());

  return (
    <div className="App" data-theme={theme}>
      <Navbar id="logo" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand onFocus={() => speakMath("Link: HomePage")} href="/">
            MyAccessible Math
          </Navbar.Brand>
          <Nav>
            <Nav.Link
              onFocus={() => speakMath("Link: Change Color Theme")}
              onClick={switchTheme}
            >
              {theme === "light" ? <FaMoon /> : <FaSun />}
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Row>
        <Col style={Styles.thanks}>
          Thank you for participating in the survey.
        </Col>
      </Row>
    </div>
  );
}

const Styles = {
  thanks: {
    marginTop: 40,
    fontSize: "30px",
    letterSpacing: 3,
  },
};

export default End;
