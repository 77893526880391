import "./App.css";
import {
  Container,
  Navbar,
  Table,
  Modal,
  Toast,
  Row,
  Col,
  Nav,
  ToastContainer,
} from "react-bootstrap";
import useSound from "use-sound";
import bad from "../src/sound/bad.mp3";
import good from "../src/sound/good.mp3";
import beep from "../src/sound/beep.mp3";
import chime from "../src/sound/chime.mp3";
import { useNavigate } from "react-router-dom";
import React from "react";
import { FaMicrophone, FaSun, FaMoon, FaPlus, FaMinus } from "react-icons/fa";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";
import { mathcompatiblespeech } from "./MathSpeech.js";
import useLocalStorage from "use-local-storage";
import KeyPress from "./keyPress";

const GET_QUESTIONS =
  "https://tettabsuu0.execute-api.us-east-1.amazonaws.com/prod/questions";

function Home() {
  const [goodSound] = useSound(good);
  const [badSound] = useSound(bad);
  const [beepSound] = useSound(beep);
  const [chimeSound] = useSound(chime);
  const navigate = useNavigate();
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const defaultVoice = window.matchMedia("(prefers-voice: 0)").matches;
  const defaultRate = window.matchMedia("(prefers-rate: 1)").matches;
  const defaultMode = window.matchMedia("(prefers-mode: 1)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );
  const [value, setValue] = useState("");
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      speech_action(result);
      setShowToast(true);
      setValue(result);
    },
  });
  const [fontSize, setFontSize] = useState(25);
  const [voiceOption, setVoice] = useLocalStorage("voice", defaultVoice);
  const [rateOption, setRate] = useLocalStorage("rate", defaultRate ? 2 : 1);
  const [modeOption, setMode] = useLocalStorage("mode", defaultMode ? 0 : 1);
  const { cancel, speak, voices } = useSpeechSynthesis();
  const [questionsData, setQuestionsData] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [show, setShow] = useState({ id: null, title: null, show: false });
  const handleClose = () => {
    setShow({ id: null, title: null, show: false });
  };
  const handleShow = (id, title) => {
    setShow({ id: id, title: title, show: true });
  };
  const speakMath = (text) => {
    cancel();
    var speech = mathcompatiblespeech(text);
    speak({
      text: speech,
      voice: voices[voiceOption],
      rate: rateOption,
    });
  };

  function speech_action(action) {
    setShowToast(action);
    if (action.includes("random") || action.includes("practice")) {
      stop();
      setTimeout(function () {
        if (modeOption === 1) {
          speakMath("Picked a question for you to practice. Redirecting now.");
        } else {
          beepSound();
        }
      }, 1000);
      setTimeout(function () {
        navigate("/solve/1");
      }, 4000);
    }

    if (action.includes("increase") || action.includes("magnify")) {
      stop();
      setTimeout(function () {
        if (modeOption === 1) {
          speakMath("Increasing font size by 10..");
        } else {
          beepSound();
        }
      }, 1000);
      setTimeout(function () {
        setFontSize(fontSize + 10);
      }, 4000);
    }

    if (action.includes("decrease")) {
      stop();
      setTimeout(function () {
        if (modeOption === 1) {
          speakMath("Decreasing font size by 10..");
        } else {
          beepSound();
        }
      }, 1000);
      setTimeout(function () {
        setFontSize(fontSize - 10);
      }, 4000);
    }

    if (action.includes("color") || action.includes("theme")) {
      stop();
      setTimeout(function () {
        switchTheme();
      }, 1000);
    }
  }

  function loadQuestions() {
    axios
      .get(GET_QUESTIONS)
      .then((response) => {
        setQuestionsData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    document.addEventListener("keyup", disableSpeech);
    loadQuestions();
  }, []);

  const enableSpeech = (event) => {
    document.getElementById("longPressMic").click();
    try {
      listen();
    } catch {
      stop();
      console.log("speech already started");
    }
    setShowToast(true);
  };

  const enableZoomIn = (event) => {
    beepSound();
    setFontSize(fontSize + 5);
  };

  const enableZoomOut = (event) => {
    beepSound();
    setFontSize(fontSize - 5);
  };

  const disableSpeech = (event) => {
    if (event.key === "s") {
      setValue("");
      setShowToast(false);
      stop();
    }
  };

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    if (modeOption === 1) {
      speakMath("Theme changed to " + newTheme);
    } else {
      beepSound();
    }
    setTheme(newTheme);
  };

  const switchVoice = (option, text) => {
    if (modeOption === 1) {
      speak({
        text: "Default Voice changed to: " + text,
        voice: voices[option],
      });
    } else {
      beepSound();
    }
    setVoice(option);
  };

  const switchRate = () => {
    if (rateOption === 1) {
      if (modeOption === 1) {
        speakMath("Changed Rate to, fast");
      } else {
        beepSound();
      }
      setRate(rateOption + 1);
    } else {
      if (modeOption === 1) {
        speakMath("Changed Rate to, slow");
      } else {
        beepSound();
      }
      setRate(rateOption - 1);
    }
  };

  const switchMode = () => {
    if (modeOption === 1) {
      if (modeOption === 1) {
        speakMath("Changed mode to, sound");
      } else {
        beepSound();
      }
      setMode(0);
    } else {
      if (modeOption === 1) {
        speakMath("Changed Rate to, speech");
      } else {
        beepSound();
      }
      setMode(1);
    }
  };

  KeyPress(["s"], enableSpeech);
  KeyPress(["shift", "+"], enableZoomIn);
  KeyPress(["shift", "-"], enableZoomOut);
  KeyPress(["shift", "T"], switchTheme);
  KeyPress(["shift", "!"], () => switchVoice(0, "U S English Male - 1"));
  KeyPress(["shift", "@"], () => switchVoice(1, "U S English Male - 2"));
  KeyPress(["shift", "#"], () => switchVoice(2, "U S English Female"));
  KeyPress(["shift", "$"], () => switchVoice(5, "U K English Female"));
  KeyPress(["shift", "%"], () => switchVoice(6, "U K English Male"));
  KeyPress(["shift", ")"], () => switchMode());
  KeyPress(["shift", "("], () => switchRate());

  return (
    <div className="App" data-theme={theme}>
      <Navbar id="logo" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand onFocus={() => speakMath("Link: HomePage")} href="/">
            MyAccessible Math
          </Navbar.Brand>
          <Nav>
            <Nav.Link
              onFocus={() => speakMath("Link: Change Color Theme")}
              onClick={switchTheme}
            >
              {theme === "light" ? <FaMoon /> : <FaSun />}
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>

      {Object.keys(questionsData).length > 0 && (
        <div>
          <Modal size="xl" show={show.show} onHide={handleClose} centered>
            <Modal.Header style={Styles.modalHeader}>
              <Modal.Title style={Styles.modalTitle}>{show.title}</Modal.Title>
            </Modal.Header>
          </Modal>
          <Table
            style={Styles.questionDataTable}
            striped
            bordered
            hover
            id="questionTable"
          >
            <thead>
              <tr
                style={{
                  fontSize: `${fontSize}px`,
                }}
              >
                <th
                  width="75%"
                  style={{
                    fontSize: `${fontSize}px`,
                  }}
                >
                  Question
                </th>
                <th>Action</th>
              </tr>
            </thead>
            {questionsData.map((question, i) => (
              <tbody key={question.qid}>
                <tr
                  style={{
                    fontSize: `${fontSize}px`,
                  }}
                >
                  <td
                    onKeyDown={(e) => {
                      if (e.key === "z") {
                        handleShow(question.qid, question.question);
                      }
                      if (e.key === "q") {
                        speakMath(question.question);
                      }
                    }}
                    id="start"
                  >
                    <span
                      contentEditable={false}
                      tabIndex={0}
                      style={Styles.inlineQuestionInput}
                      onFocus={() => {
                        speakMath("question " + (i + 1));
                      }}
                    >
                      {question.question}
                    </span>
                  </td>
                  <td>
                    <Link
                      contentEditable={false}
                      tabIndex={0}
                      style={Styles.inlineQuestionInput}
                      onFocus={() => {
                        speakMath("Link: Solve question " + (i + 1));
                      }}
                      to={`/solve/${question.qid}`}
                    >
                      Solve
                    </Link>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </div>
      )}
      <Row xs="auto" className="align-items-center">
        {/* <Col lg="3">
          <button
            className="p-3 m-3"
            tabIndex="0"
            onClick={() => setFontSize(fontSize - 5)}
          >
            <FaMinus size="2em" />
          </button>
          <button
            className="m-3 p-3"
            tabIndex="0"
            onClick={() => setFontSize(fontSize + 5)}
          >
            <FaPlus size="2em" />
          </button>
        </Col> */}
        <Col lg="4">
          <Link
            id="next_link"
            style={Styles.linkStyle}
            className="bright"
            onFocus={() => {
              speakMath("Link: Ask math questions");
            }}
            tabIndex={0}
            to={`/learn`}
          >
            Math
          </Link>
        </Col>
        <Col lg="4">
          <div
            tabIndex="-1"
            id="longPressMic"
            onMouseDown={listen}
            onMouseUp={stop}
            className="pulse-multiple"
          >
            <FaMicrophone size="3em" />
          </div>
        </Col>

        <Col lg="4">
          <Link
            id="next_link"
            style={Styles.linkStyle}
            className="bright"
            onFocus={() => {
              speakMath("Link: Start Practice");
            }}
            tabIndex={0}
            to={`/solve/1`}
          >
            Practice
          </Link>
        </Col>
      </Row>

      <ToastContainer className="p-3" position="bottom-center">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={2000}
          autohide
        >
          <Toast.Body id="toast">{value}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

const Styles = {
  inlineQuestionInput: {
    width: "100%",
    letterSpacing: 3,
    textAlign: "center",
    border: 0,
  },
  modalHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalTitle: {
    padding: 35,
    fontSize: 50,
    letterSpacing: 4,
  },
  questionDataTable: {
    marginTop: 40,
    fontSize: "25px",
    letterSpacing: 3,
  },
  linkStyle: {
    fontSize: "30px",
  },
};

export default Home;
