import { useParams } from "react-router-dom";
import {
  Container,
  Navbar,
  Button,
  Toast,
  ToastContainer,
  Table,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import useSound from "use-sound";
import bad from "../src/sound/bad.mp3";
import good from "../src/sound/good.mp3";
import beep from "../src/sound/beep.mp3";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaMicrophone, FaSun, FaMoon, FaPlus, FaMinus } from "react-icons/fa";
import { useState, useEffect } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import reactStringReplace from "react-string-replace";
import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";
import useLocalStorage from "use-local-storage";
import { mathcompatiblespeech } from "./MathSpeech.js";
import KeyPress from "./keyPress";

const GET_STEPS =
  "https://tettabsuu0.execute-api.us-east-1.amazonaws.com/prod/steps?qid=";

function Solve() {
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setShowToast(true);
      setValue(result);
      speech_array.push(result);
      let item = "";
      setTimeout(function () {
        item = speech_array.at(speech_array.length - 1);
      }, 2000);
      setTimeout(function () {
        speech_action(item);
      }, 2000);
    },
  });
  const [steps_array, setStepsInArray] = useState([]);
  const [replace_array, replaceStepsInArray] = useState([]);
  let { qid } = useParams();
  let speech_array = [];
  const [beepSound] = useSound(beep);
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  let [caret, setCaret] = useState(0);
  const [fontSize, setFontSize] = useState(30);
  const [showToast, setShowToast] = useState(false);
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const defaultVoice = window.matchMedia("(prefers-voice: 0)").matches;
  const defaultMode = window.matchMedia("(prefers-mode: 1)").matches;
  const defaultRate = window.matchMedia("(prefers-rate: 1)").matches;
  const [modeOption, setMode] = useLocalStorage("mode", defaultMode ? 0 : 1);
  const [rateOption, setRate] = useLocalStorage("rate", defaultRate ? 2 : 1);
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );
  const [voiceOption, setVoice] = useLocalStorage("voice", defaultVoice);
  const [stepsData, setSteps] = useState([]);
  const { cancel, speak, voices } = useSpeechSynthesis();
  const [goodSound] = useSound(good);
  const [badSound] = useSound(bad);
  const enableSpeech = (event) => {
    document.getElementById("longPressMic").click();
    try {
      listen();
    } catch {
      stop();
      console.log("speech already started");
    }
    setShowToast(true);
  };

  const switchRate = () => {
    if (rateOption === 1) {
      if (modeOption === 1) {
        speakMath("Changed Rate to, fast");
      } else {
        beepSound();
      }
      setRate(rateOption + 1);
    } else {
      if (modeOption === 1) {
        speakMath("Changed Rate to, slow");
      } else {
        beepSound();
      }
      setRate(rateOption - 1);
    }
  };

  function speech_action(action) {
    var item = action.replace(/ /g, "").toString().trim();
    console.log(item);
    setTimeout(function () {
      stop();
      if (steps_array.includes(item)) {
        var index = steps_array.indexOf(item);
        document.getElementById("user_input" + index).innerHTML = action;
        document.getElementById("user_input_style" + index).style[
          "backgroundColor"
        ] = "green";
        // document.getElementById("user_input_style" + index).tabIndex = "-1";
        document.getElementById("question").value = replace_array[index];
        setTimeout(function () {
          goodSound();
        }, 2000);
      } else if (item.includes("increase") || item.includes("magnify")) {
        setTimeout(function () {
          if (modeOption === 1) {
            speakMath("Increasing font size by 10..");
          } else {
            beepSound();
          }
        }, 1000);
        setTimeout(function () {
          setFontSize(fontSize + 10);
        }, 4000);
      } else if (item.includes("decrease")) {
        setTimeout(function () {
          if (modeOption === 1) {
            speakMath("Decreasing font size by 10..");
          } else {
            beepSound();
          }
        }, 1000);
        setTimeout(function () {
          setFontSize(fontSize - 10);
        }, 4000);
      } else if (item.includes("color") || item.includes("theme")) {
        setTimeout(function () {
          switchTheme();
        }, 1000);
      } else {
        badSound();
      }
    }, 5000);

    // if (action.includes("next")) {
    //   stop();
    //   setTimeout(function () {
    //     speakMath("Moving to next question.");
    //   }, 1000);
    //   setTimeout(function () {
    //     navigate_next("/solve/" + parseInt(parseInt(qid) + 1));
    //   }, 4000);
    // }
    // else {
    //   setTimeout(function () {
    //     badSound();
    //   }, 3000);
    // }
  }

  const get_steps_speech_to_compare = (data) => {
    setStepsInArray([]);
    replaceStepsInArray([]);
    data[2].map((each, i) => steps_array.push(each[1].replace(/ /g, "")));
    data[2].map((each, i) => replace_array.push(each[2].replace(/ /g, "")));
    setStepsInArray(steps_array);
    replaceStepsInArray(replace_array);
  };

  const disableSpeech = (event) => {
    if (event.key === "s") {
      setValue("");
      setShowToast(false);
      stop();
    }
  };

  const enableZoomIn = (event) => {
    setFontSize(fontSize + 3);
  };

  const enableZoomOut = (event) => {
    setFontSize(fontSize - 3);
  };

  const speakMath = (text) => {
    cancel();
    var speech = mathcompatiblespeech(text);
    speak({ text: speech, voice: voices[voiceOption] });
  };

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    if (modeOption === 1) {
      speakMath("Theme changed to " + newTheme);
    } else {
      beepSound();
    }
    setTheme(newTheme);
  };

  const switchVoice = (option, text) => {
    if (modeOption === 1) {
      speak({
        text: "Default Voice changed to: " + text,
        voice: voices[option],
      });
    } else {
      beepSound();
    }
    setVoice(option);
  };

  const getSteps = () => {
    axios
      .get(GET_STEPS + qid)
      .then((response) => {
        setSteps(response.data);
        console.log(response.data);
        setTimeout(function () {
          get_steps_speech_to_compare(response.data);
          document.getElementById("question").value = response.data[1];
          // document.getElementById("original").innerHTML = response.data[1];
          // focus_input();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function navigate_question(q, direction) {
  //   if (direction === "right") {
  //     let caret = document.getElementById("question").selectionStart;
  //     let question = q.replaceAll("\\s+", "");
  //     if (caret < question.length) {
  //       speakMath(question[caret]);
  //     }
  //   } else if (direction === "left") {
  //     let caret = document.getElementById("question").selectionEnd;
  //     let question = q.replaceAll("\\s+", "");
  //     if (caret > 0) {
  //       speakMath(question[caret - 1]);
  //     }
  //   }
  // }

  useEffect(() => {
    document.addEventListener("keyup", disableSpeech);
    getSteps();
  }, []);

  // function caret_to_start() {
  //   let element = document.querySelector("input");
  //   setTimeout(function () {
  //     element.selectionStart = 0;
  //     element.selectionEnd = 0;
  //   }, 1000);
  // }

  // function focus_input() {
  //   let element = document.querySelector("input");
  //   element.focus();
  //   caret_to_start();
  // }

  // function focus_speak_input() {
  //   speakMath("Question : " + stepsData[1]);
  //   let element = document.querySelector("input");
  //   element.focus();
  //   caret_to_start();
  // }

  const switchMode = () => {
    if (modeOption === 1) {
      if (modeOption === 1) {
        speakMath("Changed mode to, sound");
      } else {
        beepSound();
      }
      setMode(0);
    } else {
      if (modeOption === 1) {
        speakMath("Changed Rate to, speech");
      } else {
        beepSound();
      }
      setMode(1);
    }
  };

  const letsee = (text) => {
    let question = document.getElementById("question").value;
    if (text === "d") {
      if (question.length > caret) {
        setCaret(caret + 1);
        speakMath(question[caret]);
      } else {
        beepSound();
      }
    }
    if (text === "a") {
      if (caret > 0) {
        setCaret(caret - 1);
        speakMath(question[caret - 1]);
      } else {
        beepSound();
      }
    }
  };

  KeyPress(["s"], enableSpeech);
  KeyPress(["a"], () => letsee("a"));
  KeyPress(["d"], () => letsee("d"));
  KeyPress(["q"], () => speakMath(document.getElementById("question").value));
  KeyPress(["shift", "+"], enableZoomIn);
  KeyPress(["shift", "-"], enableZoomOut);
  KeyPress(["shift", "T"], switchTheme);
  KeyPress(["shift", "!"], () => switchVoice(0, "U S English Male - 1"));
  KeyPress(["shift", "@"], () => switchVoice(1, "U S English Male - 2"));
  KeyPress(["shift", "#"], () => switchVoice(2, "U S English Female"));
  KeyPress(["shift", "$"], () => switchVoice(5, "U K English Female"));
  KeyPress(["shift", "%"], () => switchVoice(6, "U K English Male"));
  KeyPress(["shift", "("], () => switchRate());
  KeyPress(["shift", ")"], () => switchMode());
  KeyPress(["shift", ")"], () => switchMode());

  // function navigate_next(path) {
  //   navigate(path);
  //   window.location.reload();
  // }

  // const setInputValue = () => {
  //   console.log(replace_array.size());
  //   if (replace_array.length === 0) {
  //     document.getElementById("question").value = stepsData[1];
  //   } else {
  //     document.getElementById("question").value = replace_array[0];
  //   }
  // };

  return (
    <div className="App" data-theme={theme}>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand
            onFocus={() => speakMath("Link: Back to questions")}
            tabIndex="0"
            href="/"
          >
            <IoArrowBackOutline /> Back
          </Navbar.Brand>
          <Nav tabIndex="-1">
            <Nav.Link
              onFocus={() => speakMath("Link: Change Color Theme")}
              onClick={switchTheme}
            >
              {theme === "light" ? <FaMoon /> : <FaSun />}
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Row>
        <p id="original"></p>
      </Row>
      <Row>
        <Col>
          <input
            tabIndex="-1"
            autoComplete="off"
            style={{
              marginBottom: 40,
              marginTop: 60,
              fontWeight: "bold",
              letterSpacing: 3,
              textAlign: "center",
              width: "100%",
              fontSize: `${fontSize + 15}px`,
            }}
            disabled="on"
            className="bright"
            id="question"
            // onKeyDown={(e) => {
            //   if (e.key === "ArrowRight") {
            //     navigate_question(stepsData[1], "right");
            //   }
            //   if (e.key === "ArrowLeft") {
            //     navigate_question(stepsData[1], "left");
            //   }
            // }}
          />
        </Col>
      </Row>
      {Object.keys(stepsData).length > 0 && (
        <div>
          <Table
            style={Styles.stepsDataTable}
            striped
            bordered
            hover
            id="stepsTable"
          >
            <thead>
              <tr
                style={{
                  fontSize: `${fontSize}px`,
                }}
              >
                <th>#</th>
                <th>Step</th>
                <th>Result</th>
              </tr>
            </thead>
            {stepsData[2].map((each, i) => (
              <tbody key={i}>
                <tr
                  onFocus={() =>
                    speakMath("Step : " + parseInt(i + 1) + " : " + each[0][0])
                  }
                  tabIndex={0}
                  id={"user_input_style" + i}
                  style={{
                    fontSize: `${fontSize}px`,
                  }}
                >
                  <td className="col-md-1">
                    <span>{i + 1}</span>
                  </td>
                  <td className="col-md-5">
                    <span>{each[0][0]}</span>
                  </td>
                  <td
                    className="col-md-3"
                    // onFocus={() => speakMath("Result : " + each[1])}
                  >
                    <span>{each[1]}</span>
                  </td>
                  <td className="col-md-3">
                    <span id={"user_input" + i}></span>
                  </td>
                  {/* <td>
                    <span>
                      {reactStringReplace(each[2], each[1], (match, i) => (
                        <span className="bright" key={i}>
                          {match}
                        </span>
                      ))}
                    </span>
                  </td> */}
                </tr>
              </tbody>
            ))}
          </Table>
        </div>
      )}
      <Row xs="auto" className="align-items-center">
        {/* <Col lg="4">
          <button
            className="p-3 m-3"
            tabIndex="-1"
            onClick={() => setFontSize(fontSize - 3)}
          >
            <FaMinus size="2em" />
          </button>
          <button
            className="m-3 p-3"
            tabIndex="-1"
            onClick={() => setFontSize(fontSize + 3)}
          >
            <FaPlus size="2em" />
          </button>
        </Col> */}
        <Col lg="12">
          <div
            tabIndex="-1"
            id="longPressMic"
            onMouseDown={listen}
            onMouseUp={stop}
            className="pulse-multiple"
          >
            <FaMicrophone size="3em" />
          </div>
        </Col>

        {/* {qid < 4 && (
          <Col>
            <Button
              id="next_link"
              style={Styles.linkStyle}
              className="bright"
              onFocus={() => {
                speakMath("Link: Next Question");
              }}
              tabIndex={0}
              onClick={() =>
                navigate_next("/solve/" + parseInt(parseInt(qid) + 1))
              }
            >
              Next Question
            </Button>
          </Col>
        )} */}

        {/* {qid >= 4 && (
          <Col>
            <Button
              style={Styles.linkStyle}
              className="bright"
              onFocus={() => {
                speakMath("Link: Exit");
              }}
              tabIndex={0}
              onClick={() => navigate_next("/end")}
            >
              Exit
            </Button> 
          </Col>
        )} */}
      </Row>
      <ToastContainer className="p-3" position="bottom-center">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={2000}
          autohide
        >
          <Toast.Body id="toast">{value}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

const Styles = {
  stepsDataTable: {
    marginTop: 40,
    fontSize: "30px",
    letterSpacing: 3,
  },
  linkStyle: {
    fontSize: "30px",
  },
};

export default Solve;
