import "./App.css";
import {
  Container,
  Navbar,
  Table,
  Modal,
  Toast,
  Row,
  Col,
  Nav,
  ToastContainer,
} from "react-bootstrap";
import useSound from "use-sound";
import { useNavigate } from "react-router-dom";
import React from "react";
import { FaMicrophone, FaSun, FaMoon, FaPlus, FaMinus } from "react-icons/fa";
import axios from "axios";
import { useState, useEffect } from "react";
import beep from "../src/sound/beep.mp3";
import { Link } from "react-router-dom";
import { useSpeechSynthesis, useSpeechRecognition } from "react-speech-kit";
import { mathcompatiblespeech } from "./MathSpeech.js";
import useLocalStorage from "use-local-storage";
import { IoArrowBackOutline } from "react-icons/io5";
import KeyPress from "./keyPress";

function Learn() {
  const GET_HELPER =
    "https://ofru8cbmjh.execute-api.us-east-1.amazonaws.com/helper?q=";
  const navigate = useNavigate();
  const [beepSound] = useSound(beep);
  const [fontSize, setFontSize] = useState(25);
  const [showToast, setShowToast] = useState(false);
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const defaultVoice = window.matchMedia("(prefers-voice: 0)").matches;
  const [voiceOption, setVoice] = useLocalStorage("voice", defaultVoice);
  const { cancel, speak, voices } = useSpeechSynthesis();
  const defaultMode = window.matchMedia("(prefers-mode: 1)").matches;
  const [modeOption, setMode] = useLocalStorage("mode", defaultMode ? 0 : 1);
  const defaultRate = window.matchMedia("(prefers-rate: 1)").matches;
  const [rateOption, setRate] = useLocalStorage("rate", defaultRate ? 2 : 1);
  const [responseData, setResponseData] = useState([]);
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );
  const [value, setValue] = useState("");
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      speech_action(result);
      setShowToast(true);
      setValue(result);
    },
  });

  const switchRate = () => {
    if (rateOption === 1) {
      if (modeOption === 1) {
        speakMath("Changed Rate to, fast");
      } else {
        beepSound();
      }
      setRate(rateOption + 1);
    } else {
      if (modeOption === 1) {
        speakMath("Changed Rate to, slow");
      } else {
        beepSound();
      }
      setRate(rateOption - 1);
    }
  };

  function speech_action(action) {
    setShowToast(action);
    if (action.includes("increase") || action.includes("magnify")) {
      stop();
      setTimeout(function () {
        if (modeOption === 1) {
          speakMath("Increasing font size by 10..");
        } else {
          beepSound();
        }
      }, 1000);
      setTimeout(function () {
        setFontSize(fontSize + 10);
      }, 4000);
    }

    if (action.includes("decrease")) {
      stop();
      setTimeout(function () {
        if (modeOption === 1) {
          speakMath("Decreasing font size by 10..");
        } else {
          beepSound();
        }
      }, 1000);
      setTimeout(function () {
        setFontSize(fontSize - 10);
      }, 4000);
    }

    if (action.includes("color") || action.includes("theme")) {
      stop();
      setTimeout(function () {
        switchTheme();
      }, 1000);
    }

    if (action.includes("question")) {
      let question = action.replace("question", "");
      console.log(question);
      if (question !== "") {
        loadResponse(question);
      }
    }
  }

  const speakMath = (text) => {
    cancel();
    var speech = mathcompatiblespeech(text);
    speak({ text: speech, voice: voices[voiceOption] });
  };

  const enableZoomIn = (event) => {
    setFontSize(fontSize + 3);
  };

  const enableZoomOut = (event) => {
    setFontSize(fontSize - 3);
  };

  const disableSpeech = (event) => {
    if (event.key === "s") {
      setValue("");
      setShowToast(false);
      stop();
    }
  };

  const enableSpeech = (event) => {
    document.getElementById("longPressMic").click();
    try {
      listen();
    } catch {
      stop();
      console.log("speech already started");
    }
    setShowToast(true);
  };

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    if (modeOption === 1) {
      speakMath("Theme changed to " + newTheme);
    } else {
      beepSound();
    }
    setTheme(newTheme);
  };

  const switchVoice = (option, text) => {
    if (modeOption === 1) {
      speak({
        text: "Default Voice changed to: " + text,
        voice: voices[option],
      });
    } else {
      beepSound();
    }
    setVoice(option);
  };

  const switchMode = () => {
    if (modeOption === 1) {
      if (modeOption === 1) {
        speakMath("Changed mode to, sound");
      } else {
        beepSound();
      }
      setMode(0);
    } else {
      if (modeOption === 1) {
        speakMath("Changed Rate to, speech");
      } else {
        beepSound();
      }
      setMode(1);
    }
  };

  KeyPress(["s"], enableSpeech);
  KeyPress(["shift", "+"], enableZoomIn);
  KeyPress(["shift", "-"], enableZoomOut);
  KeyPress(["shift", "T"], switchTheme);
  KeyPress(["shift", "!"], () => switchVoice(0, "U S English Male - 1"));
  KeyPress(["shift", "@"], () => switchVoice(1, "U S English Male - 2"));
  KeyPress(["shift", "#"], () => switchVoice(2, "U S English Female"));
  KeyPress(["shift", "$"], () => switchVoice(5, "U K English Female"));
  KeyPress(["shift", "%"], () => switchVoice(6, "U K English Male"));
  KeyPress(["shift", "("], () => switchRate());
  KeyPress(["shift", ")"], () => switchMode());

  function handleSubmit(event) {
    event.preventDefault();
    const { question } = event.target.elements;
    loadResponse(question.value);
  }

  function loadResponse(question) {
    let URL_HELPER = GET_HELPER + question;
    console.log(URL_HELPER);
    stop();
    axios
      .get(URL_HELPER)
      .then((response) => {
        console.log(response);
        setResponseData(response.data);
        setTimeout(function () {
          speakMath(response.data);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="App" data-theme={theme}>
      <Navbar id="logo" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand onFocus={() => speakMath("Link: HomePage")} href="/">
            <IoArrowBackOutline /> Back
          </Navbar.Brand>
          <Nav>
            <Nav.Link
              onFocus={() => speakMath("Link: Change Color Theme")}
              onClick={switchTheme}
            >
              {theme === "light" ? <FaMoon /> : <FaSun />}
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Row>
        <Col style={Styles.thanks}>
          <form
            style={{
              fontSize: `${fontSize}px`,
            }}
            onSubmit={handleSubmit}
          >
            <label>
              Enter your question:
              <input
                className="questionText"
                type="text"
                name="question"
                autoComplete="off"
                style={Styles.inputStyle}
              />
            </label>
          </form>
        </Col>
      </Row>
      <Row style={Styles.responseBlock}>
        <Col style={Styles.responseText}>
          {Object.keys(responseData).length > 0 && (
            <span
              className="questionText"
              style={{
                fontSize: `${fontSize}px`,
              }}
            >
              {responseData}
            </span>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <div
            tabIndex="-1"
            id="longPressMic"
            onMouseDown={listen}
            onMouseUp={stop}
            className="pulse-multiple"
          >
            <FaMicrophone size="3em" />
          </div>
        </Col>
      </Row>
      <ToastContainer className="p-3" position="bottom-center">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={2000}
          autohide
        >
          <Toast.Body id="toast">{value}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

const Styles = {
  thanks: {
    marginTop: 40,
    fontSize: "30px",
    letterSpacing: 3,
  },
  inputStyle: {
    marginTop: 25,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "black",
  },
  responseText: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: "30px",
    letterSpacing: 3,
  },
  responseBlock: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 100,
    marginBottom: 100,
    height: 400,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "black",
  },
};

export default Learn;
